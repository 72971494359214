var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-page mt-2"},[_c('div',{staticClass:"px-3 py-2"},[_c('div',{staticClass:"title mb-3"},[_c('span',{staticClass:"mr-2 circle"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}})],1),_vm._v(" คะแนนสะสมปัจจุบัน ")]),_c('b-card',{staticClass:"mt-3 border-none card-shadow"},[_c('div',{staticClass:"content-between"},[_c('div',[_vm._v("คะแนนพิเศษ")]),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push('/point')}}},[_c('span',{staticClass:"mr-2 circle"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}})],1),_vm._v(" "+_vm._s(_vm.totalPoint.toLocaleString())+" "),_c('span',{staticClass:"ml-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1)])])]),(_vm.pointLog.length > 0)?_c('b-card',{staticClass:"mt-3 border-none card-shadow"},[_c('div',{staticClass:"mb-2 f-16"},[_vm._v("คะแนนสาขาของคุณ")]),_vm._l((_vm.pointLog),function(branch){return _c('div',{key:branch.branch_id,staticClass:"row"},[(branch.sum_point != 0)?_c('div',{staticClass:"content-between f-md text-whitesmoke col-12"},[_c('div',[_vm._v(_vm._s(branch.name))]),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push('/point?branch_id=' + branch.branch_id)}}},[_vm._v(" "+_vm._s(branch.sum_point.toLocaleString())+" คะแนน "),_c('span',{staticClass:"ml-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1)])]):_vm._e()])})],2):_vm._e(),(
        _vm.isServiceShow &&
        (_vm.creditAndPackge.current_credit != 0 ||
          _vm.creditAndPackge.total_package != 0)
      )?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"title mb-3"},[_c('span',{staticClass:"mr-2 circle"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'gifts']}})],1),_vm._v(" คลาแรงส์สปา ")]),(_vm.creditAndPackge.current_credit != 0)?_c('b-card',{staticClass:"mt-3 border-none card-shadow"},[_c('div',{staticClass:"content-between"},[_c('div',[_vm._v("เครดิตคงเหลือ")]),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push('/point/log/credit')}}},[_c('font-awesome-icon',{staticClass:"icon-primary",attrs:{"icon":['fas', 'credit-card']}}),_vm._v(" "+_vm._s(_vm.creditAndPackge.current_credit.toLocaleString())+" "),_c('span',{staticClass:"ml-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1)],1)])]):_vm._e(),(_vm.creditAndPackge.total_package != 0)?_c('b-card',{staticClass:"mt-3 border-none card-shadow"},[_c('div',{staticClass:"content-between"},[_c('div',[_vm._v("แพ็คเกจ")]),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push('/point/log/package')}}},[_c('font-awesome-icon',{staticClass:"icon-primary",attrs:{"icon":['fas', 'gifts']}}),_vm._v(" "+_vm._s(_vm.creditAndPackge.total_package.toLocaleString())+" "),_c('span',{staticClass:"ml-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1)],1)])]):_vm._e()],1):_vm._e()],1),(
      _vm.$store.state.theme.is_center_point == '0' &&
      _vm.$store.state.theme.line_layout == 'Compact'
    )?_c('Footer',{attrs:{"text":"กลับสู่หน้าหลัก"},on:{"onClick":function($event){return _vm.$router.push('/profile')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }