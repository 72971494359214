<template>
  <div>
    <div class="total-point">
      <div
        class="point-panel text-center"
        style="border-right: unset"
        v-if="$store.state.theme.line_layout == 'Compact'"
      >
        <div class="f-md text-gray">
          <div>คะแนนสะสม</div>
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <!-- <span class="fa-stack fa-xs">
            <font-awesome-icon
              :icon="['fas', 'circle']"
              class="fa-stack-2x"
            /><font-awesome-icon
              :icon="['fas', 'star']"
              class="fa-stack-1x text-white"
            />
          </span> -->
          <span class="fa-stack fa-1x icon-background mr-2">
            <b-img
              :src="$store.state.domain + $store.state.theme.point_logo"
              class="img-point"
              v-if="$store.state.theme.point_logo"
            ></b-img>
            <!-- {{ $store.state.domain + $store.state.theme.point_logo }} -->
            <font-awesome-icon v-else icon="star" class="point-icon-color" />
            <!-- :color="item.theme_icon_color" -->
            <!-- <font-awesome-icon icon="star" class="point-icon-color" /> -->
          </span>

          <span class="f-xl f-bold">{{ total | numeral("0,0") }}</span>
        </div>
      </div>

      <b-row v-else>
        <b-col>
          <font-awesome-icon :icon="['fas', 'gift']" />
          <span class="ml-1 text-nowrap"> คะแนนสะสมปัจจุบัน </span>
        </b-col>
        <b-col class="text-right">
          <span class="point-count">{{ total | numeral("0,0") }} คะแนน</span>
        </b-col>
      </b-row>

      <div class="container-point" v-if="pointHistoryLog.length > 0">
        <hr />
        <b-row class="f-sm row-gap-sm">
          <template v-for="(point, index) of pointHistoryLog">
            <b-col cols="6" :key="'point-' + index" v-if="index < 3">
              <span class="text-nowrap">
                หมดอายุ
                <span v-if="point.expired_date">{{
                  $moment(point.expired_date)
                    .add(543, "y")
                    .format("DD MMM YYYY")
                }}</span>
                <span v-else>-</span>
              </span>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
              :key="'point+-' + index"
              v-if="index < 3"
            >
              <span>{{ point.point | numeral("0,0") }} คะแนน</span>
            </b-col>

            <div
              class="col-12"
              v-if="index == 3"
              :key="'collapse-index' + index"
            >
              <b-collapse
                v-model="isShow"
                :id="'accordion-point'"
                ref="collapse"
              >
                <b-row>
                  <template v-for="(point, index) of pointHistoryLog">
                    <b-col cols="6" :key="'point-' + index" v-if="index >= 3">
                      <span class="text-nowrap">
                        หมดอายุ
                        <span v-if="point.expired_date">{{
                          $moment(point.expired_date)
                            .add(543, "y")
                            .format("DD MMM YYYY")
                        }}</span>
                        <span v-else>-</span>
                      </span>
                    </b-col>
                    <b-col
                      cols="6"
                      class="text-right"
                      :key="'point+-' + index"
                      v-if="index >= 3"
                    >
                      <span>{{ point.point | numeral("0,0") }} คะแนน</span>
                    </b-col>
                  </template>
                </b-row>
              </b-collapse>
              <div class="text-center">
                <b-button v-b-toggle="'accordion-point'">
                  <font-awesome-icon
                    v-if="isShow"
                    icon="chevron-up"
                    class="color-gray"
                  />
                  <font-awesome-icon
                    v-else
                    icon="chevron-down"
                    class="color-gray"
                  />
                </b-button>
              </div>
            </div>
          </template>
        </b-row>
      </div>
    </div>
    <b-card class="border-none card-shadow my-3" v-if="!isLoading">
      <div class="content-point">
        <b-row class="f-md">
          <b-col class="text-nowrap" cols="6">จำนวนคะแนนที่ได้รับ/ใช้งาน</b-col>
          <b-col class="text-right text-nowrap" cols="6">
            คะแนนสะสมทั้งหมด
          </b-col>
        </b-row>
        <div v-if="list.length > 0">
          <div
            class="py-2 my-2 point-list"
            v-for="(item, index) in list"
            :key="index"
          >
            <b-row class="mx-1">
              <b-col>
                <div v-if="Math.sign(item.point) == -1">
                  <span class="earn_point">{{
                    item.point | numeral("0,0")
                  }}</span>
                </div>
                <div v-else>
                  <span class="point">+{{ item.point | numeral("0,0") }}</span>
                </div>
                <div class="f-sm color-gray mt-2" v-if="item.created_time">
                  {{ dateFormat(item.created_time) }}
                </div>
              </b-col>
              <b-col class="text-right">
                <div class="point-time">
                  {{ item.current_point | numeral("0,0") }}
                </div>
                <div class="f-sm color-gray mt-2">
                  {{ item.branch_name ? item.branch_name : "-" }}
                </div>
              </b-col>
            </b-row>
            <div
              class="px-3"
              v-if="item.product.length > 0 && !item.show && isCollapse"
            >
              <hr class="mb-0" />
            </div>
            <div v-if="item.product.length > 0 && isCollapse">
              <div class="text-center" v-show="item.show">
                <b-button
                  @click.prevent="hide(index)"
                  v-b-toggle="'accordion-productdetails' + index"
                >
                  <font-awesome-icon
                    icon="chevron-down"
                    class="color-gray"
                    v-if="item.show"
                  />
                </b-button>
              </div>
              <b-collapse
                :id="'accordion-productdetails' + index"
                ref="collapse"
                class="mt-2"
              >
                <b-row
                  class="mx-1 mb-2 f-md color-gray"
                  v-for="(product, i) in item.product"
                  :key="i"
                >
                  <b-col cols="8" class="line-clamp-2">{{
                    product.name
                  }}</b-col>
                  <b-col cols="4" class="text-right">
                    <div>{{ product.quantity }}</div>
                    <div>
                      {{
                        (product.price * product.quantity) | numeral("0,0.00")
                      }}
                    </div>
                  </b-col>
                </b-row>
                <div class="px-3"><hr class="my-2" /></div>
                <b-row class="mx-2">
                  <b-col class="f-md f-bold" cols="8"
                    >{{ item.product.length }} รายการ</b-col
                  >
                  <b-col class="text-right f-md f-bold" cols="4">{{
                    item.total | numeral("0,0.00")
                  }}</b-col>
                </b-row>
                <div class="text-center">
                  <b-button
                    v-b-toggle="'accordion-productdetails' + index"
                    @click.prevent="show(index)"
                  >
                    <font-awesome-icon icon="chevron-up" class="color-gray" />
                  </b-button>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="notfound-data">ไม่พบข้อมูล</div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: {
      required: true,
      type: Array
    },
    total: {
      required: true,
      type: Number
    },
    isCollapse: {
      required: true,
      default: true,
      type: Boolean
    },
    pointHistoryLog: {
      required: false,
      default: []
    }
  },
  data() {
    return {
      isLoading: true,
      isShow: false
    };
  },
  created() {
    for (const item of this.list) {
      item.show = true;
    }
    this.isLoading = false;
  },
  methods: {
    dateFormat(value, format) {
      if (!value) return;
      if (!format) format = "DD-MM-YYYY";
      return moment(value).locale("th").format("DD MMM YYYY HH:mm");
    },
    hide(index) {
      this.isLoading = true;
      this.list[index].show = false;
      this.isLoading = false;
    },
    show(index) {
      this.isLoading = true;
      this.list[index].show = true;
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.container-point {
  background: #e5e5e5;
  padding: 0.5rem;
}
.total-point {
  background-color: #fff;
  color: #000;
  border-radius: 0.25rem;
  padding: 10px;
  box-shadow: 0px 0px 6px -3px black;
}

.pd-3px {
  padding: 0 25px;
  font-size: var(--text-md);
}
.content-point {
  background-color: #fff;
  border-radius: 5px;
}
.point {
  color: #76ad73;
  font-weight: 600;
}
.earn_point {
  color: red;
  font-weight: 600;
}
.point-list {
  background-color: #e5e5e5;
  border-radius: 5px;
  margin: 0;
}
.ft-14 {
  font-size: var(--text-md);
}
.point-time {
  font-weight: 700;
}
.color-gray {
  color: #747474;
}
.btn-secondary {
  background-color: transparent;
  border: none;
}

::v-deep .btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  background-color: transparent;
  box-shadow: none;
}
::v-deep .btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
}

::v-deep .btn {
  padding: 0;
}
.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
