<template>
  <div class="content-page mt-2">
    <div class="px-3 py-2">
      <div class="title mb-3">
        <span class="mr-2 circle">
          <font-awesome-icon :icon="['fas', 'star']"
        /></span>
        คะแนนสะสมปัจจุบัน
      </div>
      <b-card class="mt-3 border-none card-shadow"
        ><div class="content-between">
          <div>คะแนนพิเศษ</div>
          <div @click="$router.push('/point')" class="cursor-pointer">
            <span class="mr-2 circle">
              <font-awesome-icon :icon="['fas', 'star']"
            /></span>
            {{ totalPoint.toLocaleString() }}
            <span class="ml-2"
              ><font-awesome-icon :icon="['fas', 'chevron-right']"
            /></span>
          </div>
        </div>
      </b-card>
      <b-card class="mt-3 border-none card-shadow" v-if="pointLog.length > 0">
        <div class="mb-2 f-16">คะแนนสาขาของคุณ</div>
        <div class="row" v-for="branch of pointLog" :key="branch.branch_id">
          <div
            v-if="branch.sum_point != 0"
            class="content-between f-md text-whitesmoke col-12"
          >
            <div>{{ branch.name }}</div>
            <div
              class="cursor-pointer"
              @click="$router.push('/point?branch_id=' + branch.branch_id)"
            >
              {{ branch.sum_point.toLocaleString() }} คะแนน
              <span class="ml-2"
                ><font-awesome-icon :icon="['fas', 'chevron-right']"
              /></span>
            </div>
          </div>
        </div>
      </b-card>
      <div
        class="mt-3"
        v-if="
          isServiceShow &&
          (creditAndPackge.current_credit != 0 ||
            creditAndPackge.total_package != 0)
        "
      >
        <div class="title mb-3">
          <span class="mr-2 circle">
            <font-awesome-icon :icon="['fas', 'gifts']"
          /></span>
          คลาแรงส์สปา
        </div>
        <b-card
          class="mt-3 border-none card-shadow"
          v-if="creditAndPackge.current_credit != 0"
          ><div class="content-between">
            <div>เครดิตคงเหลือ</div>
            <div
              class="cursor-pointer"
              @click="$router.push('/point/log/credit')"
            >
              <font-awesome-icon
                :icon="['fas', 'credit-card']"
                class="icon-primary"
              />
              {{ creditAndPackge.current_credit.toLocaleString() }}
              <span class="ml-2"
                ><font-awesome-icon :icon="['fas', 'chevron-right']"
              /></span>
            </div>
          </div>
        </b-card>
        <b-card
          class="mt-3 border-none card-shadow"
          v-if="creditAndPackge.total_package != 0"
          ><div class="content-between">
            <div>แพ็คเกจ</div>
            <div
              class="cursor-pointer"
              @click="$router.push('/point/log/package')"
            >
              <font-awesome-icon
                :icon="['fas', 'gifts']"
                class="icon-primary"
              />
              {{ creditAndPackge.total_package.toLocaleString() }}
              <span class="ml-2"
                ><font-awesome-icon :icon="['fas', 'chevron-right']"
              /></span>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <Footer
      v-if="
        $store.state.theme.is_center_point == '0' &&
        $store.state.theme.line_layout == 'Compact'
      "
      @onClick="$router.push('/profile')"
      text="กลับสู่หน้าหลัก"
    />
  </div>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb.vue";
import PointLogComponent from "../../../components/point/PointLogComponent.vue";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    Breadcrumb,
    PointLogComponent,
    OtherLoading
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "ประวัติการใช้คะแนน",
          to: "/profile"
        }
      ],
      pointLog: [],
      totalPoint: 0,
      isLoading: true,
      isCollapse: true,
      creditAndPackge: { current_credit: 0, total_package: 0 },
      requestData: { BranchId: 0 },
      isServiceShow:
        this.$store.state.theme.line_display_service_outstanding == "1"
          ? true
          : false
    };
  },
  async created() {
    await this.getPointHistoryLog();
    await this.getCreditAndPackage();
  },
  methods: {
    async getPointHistoryLog() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/user/GetUserPointBranch`)
        .then(data => {
          this.totalPoint = data.detail.center_point;
          this.pointLog = data.detail.user_point_branch_list;
        });
    },
    async getCreditAndPackage() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Service/spa_detail`)
        .then(data => {
          // this.totalPoint = data.detail.center_point;
          this.creditAndPackge = data.detail;
          // this.pointLog = data.detail.user_point_branch_list;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.point-content {
  margin: 1rem;
}
.border-none {
  border: unset;
}
.title {
  //   color: var(--primary-color);
  font-size: var(--text-lg);
  font-weight: bold;
}
.text-whitesmoke {
  color: #666666c4;
}
.circle {
  border-radius: 50%;
  padding: 3px 6px;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  font-size: var(--text-sm);
}
.icon-primary {
  color: var(--primary-color);
}
</style>
